






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: "Organizations",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              name: {
                attrs: {
                  label: "Organization",
                },
                rules: {
                  match: {},
                },
              },
              subdomain: {
                attrs: {
                  label: "Subdomain",
                },
                rules: {
                  match: {},
                },
              },
              email: {
                attrs: {
                  label: "Business Email",
                },
                rules: {
                  match: {},
                },
              },
            },
          },
          displayFields: {
            name: {
              text: "Organization",
              sortable: true,
            },
            subdomain: {
              text: "Subdomain",
              sortable: true,
            },
            email: {
              text: "Business Email",
              sortable: true,
            },
            isPostpaid: {
                text: "Payment methods",
                options: {
                  boolean: true,
                  label: (value: boolean) => {
                      return value ? "Postpaid" : "Prepaid";
                  },
                  attrs(value) {
                      return {
                          color: value ? "red" : "green",
                      };
                  },
              },
            },
            crossPayment: {
              text: "Cross Payment",
              options: {
                boolean: true,
                label: (value: boolean) => {
                    return value ? "Yes" : "No";
                },
                attrs(value) {
                    return {
                        color: value ? "red" : "green",
                    };
                },
              }
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {
              text: "Actions",
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin"]) && {},
              setActive: {
                content: {
                  icon: "mdi-hexagram",
                  iconAttrsFunc(attrs: any = {}, self) {
                    const { item } = self.context();
                    const activeTenant = appService.getActiveTenant();
                    if (activeTenant && activeTenant._id === item._id) {
                      attrs.color = "primary";
                    }
                    return attrs;
                  },
                },
                on: {
                  xClick({ self }) {
                    const { item, dataTable } = self.context();
                    const activeTenant = appService.getActiveTenant();
                    if (activeTenant && activeTenant._id === item._id) {
                      appService.deleteActiveTenant();
                      return;
                    }
                    appService.setActiveTenant(item);
                    dataTable.info.value = `Organization: ${item.name} is currently active`;
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Set Active",
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("tenants", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("tenants", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "tenants",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("tenants", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Organization",
                  required: true,
                },
              },
              subdomain: {
                attrs: {
                  label: "Subdomain",
                  required: true,
                },
              },
              email: {
                type: "email",
                attrs: {
                  label: "Business Email",
                  required: true,
                },
              },
              isPostpaid: {
                type: "boolean",
                attrs: {
                  label: "Postpaid",
                },
              },
              crossPayment: {
                type: "boolean",
                attrs: {
                  label: "Cross Payment",
                },
                ext: {
                  defaultValue: true,
                },
              },
            },
            insertForm: userManager.checkRole(["systemAdmin", "admin"]) && {
              content: {
                fieldNames: ["name", "subdomain", "email", "isPostpaid", "crossPayment"],
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin"]) && {
              content: {
                fieldNames: ["name", "subdomain", "email"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
